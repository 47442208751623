import React, {useState, useEffect } from 'react'
import ClaimSetupForm from '../../common/components/claim-setup-form/ClaimSetupForm'
import ContentMarketing from '../../common/components/content-marketing/ContentMarketing'
import { generatingTargetedLeads, contentMarketingStrategies, ActionableLeads, pricingTable } from '../../utils/outbound-lead-generation/Data'
import Button from '../../common/components/button/Button'
import DistributionStrategy from '../../common/components/distribution-strategy/DistributionStrategy'
import Service from '../../common/components/service/Service'
import './outbound-lead-generation.css'
import WeGuaranteeSatisfaction from '../../common/components/we-guarantee-satisfaction/WeGuaranteeSatisfaction'
import Company from '../../common/components/company/Company'
import PlanCard from '../../common/components/plan-card/PlanCard'
import ClaimYourFreeSetupStrategy from '../../common/components/claim-your-free-setup-strategy/ClaimYourFreeSetupStrategy'
import Underline from '../../common/components/underline/Underline'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import SEO from '../../common/components/ui/seo/SEO'


export default function OutboundLeadGenerationPage() {
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <>
      <SEO
        title='Generating targeted leads and unlocking opportunities for your brand.'
        description='Working with The Contact Center  professionals with vast lead generation experience also makes sense.Our goal is simple – Integrate with our client’s sales and marketing effort to become a new profit center. We do everything to deliver the highest possible ROI.'
        name='Say Goodbye to Missed Opportunities.'
        type='Telemarketing' />
      <Underline className={'d-block d-sm-none mt-3'}/>
      <div className="container">
        <div className="row py-2 py-lg-5 my-xl-5">
          <div className="col-12 col-lg-6">
            <ContentMarketing contentMarketing={generatingTargetedLeads}/>
          </div>
          <div className="col-12 col-lg-6">
            <div className="claim-setup-form-section-leads position-relative">
              <ClaimSetupForm />
            </div>
          </div>
        </div>
        <div className="info-section-container py-5 mt-lg-5 text-center">
          <p className="font-poppins-semi-bold mb-1 primary-color default-sm-description">You can cut through the noise with telemarketing.</p>
          <h2 className="font-poppins-bold custom-dark-color mb-lg-5 default-special-xl-title">
            Say <span className='primary-color'>Goodbye </span>  to Missed Opportunities.
          </h2>
          <p className="mb-4 default-sm-description">Marketers strive to capture the interest of prospective clients, positioning them to enter the sales pipeline through a process called lead generation. There are a number of methods and channels lead generation companies use to identify intent and stimulate interest in potential clients. There are so many, in fact, that there is a lot of noise to contend with within today’s market.</p>
          <p className="mb-1 default-sm-description">Lead generation requires business development expertise, a focus on a pre-sales process intended to exclude time wasters in order to produce actionable leads that are rigorously profiled and properly qualified.</p>
          <div className="py-4">
            <Button text={'Let’s Connect'} className={'btn primary-btn mt-0'} type={'button'} />
          </div>
        </div>
        <DistributionStrategy contentMarketingStrategies={contentMarketingStrategies}/>
        <div className="info-section-container py-5 mt-lg-5 text-center">
          <p className="font-poppins-semi-bold mb-1 primary-color">Targeting Opportunities</p>
          <div className="text-center">
            <h2 className="font-poppins-bold custom-dark-color mb-lg-5 default-special-xl-title">
              Identify and Convert New Business Opportunities Targeting Companies Your Sales People Could Not Reach.
            </h2>
          </div>
          <p className="mb-4 default-sm-description">Sales people desire to engage with prospects who demonstrated Intent to buy, have a clear time line, identified the necessary budget and are ready to talk. Some products/services require a simple sales process, others are more complex and take longer to complete. Some companies operate in a highly competitive environment while others enjoy a lower competitive landscape.</p>
          <p className="mb-1 default-sm-description">All companies desire to identify new sales opportunities that match their best client criteria. The Contact Center  will place your sales team in front of new opportunities your organization have yet to discover.</p>
          <div className="py-4">
            <Button text={'Let’s Connect'} className={'btn primary-btn mt-0'} type={'button'} />
          </div>
        </div>
      </div>
      <div className="py-5 primary-bg-light">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <p className="font-poppins-semi-bold mb-1 default-special-lg-description mb-3">Actionable lead</p>
              <h2 className="font-poppins-bold custom-dark-color mb-3 default-special-xl-title">
                <span className='primary-color'>Lead Information </span> your salespeople can act on.
              </h2>
              <p className="default-description mb-1 mb-3">
                Prospects that are qualified to have a business need for your product/services, have identified a budget, timing for decision is short, and have purchasing authority. Since they are properly profiled and qualified, they are worth your salespeople’s time and resources for further engagement, nurturing and direct sales efforts.
              </p>
              <p className='default-sm-description'>The Contact Center. has been qualifying leads since 1991 for companies like DELL, HP, Motorola, SAP, and smaller organizations like KnowB4 and Code 42 to name a few.</p>
              <p className='default-sm-description'>We have proven to our customers that we can fill their sales funnel with qualified actionable leads while saving them money in the process. (cost per lead)</p>
            </div>
            <div className="col-12 py-lg-3">
              <div className="row actionable-lead-section">
                {ActionableLeads.map((ActionableLead, index) => (
                  <div className={`col-12 col-lg-8 col-xl-4 ${ActionableLead.offSet} offset-0 offset-xl-0`} key={index}>
                    <Service
                      title={ActionableLead.title}
                      description={ActionableLead.description}
                      url={ActionableLead.url}
                    />
                  </div>
                ))}
              </div>
              <div className="pt-4 text-center">
                <Button text={'Let’s Connect'} className={'btn primary-btn mt-0'} type={'button'} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="pt-lg-5 mb-5 we-guarantee-satisfaction position-relative">
          <div className="pt-0 pt-5 container">
            <div className="text-center mx-2 mx-md-5 mx-lg-5 px-0">
              <h2 className="font-poppins-bold custom-dark-color mx-lg-5 default-special-xl-title">
                <span className='primary-color'>Cost Analysis:</span>
                Outsource, or keep Lead Generation In-House?
              </h2>
              <p className="default-sm-description mb-1">Compare you all-inclusive fully Loaded annual investment to outsource Lead generation of only $63,000 $85,000 a year to the below real fully loaded costs of your in-house Telemarketing or Inside Sales support agents (not taking into account your cost associated with Attrition).</p>
            </div>
            <div className="row pt-3 pt-lg-5 plans-table-section h-100">
              {isSmallScreen ? (
                pricingTable.map((pTable, index) => (
                  <div key={index} className={`${index % 2 === 0 ? 'even-plan' : 'odd-plan'} py-3 h-100 even-plan`}>
                    <PlanCard
                      title={pTable.title}
                      planItems={pTable.planItems}
                      totalAmount={pTable.totalAmount}
                      type={pTable.type}
                    />
                  </div>
                ))
              ) : (
                <Swiper
                  slidesPerView={'auto'}
                  direction={'horizontal'}
                  breakpoints={{
                    768: {
                      slidesPerView: 2,
                      spaceBetween: 20,
                    },
                    1025: {
                      slidesPerView: 3,
                      spaceBetween: 20,
                    },
                  }}
                  autoHeight={true}
                  modules={[Autoplay]}
                  loop={true}
                  className='swipe-slider-vertical h-100 swipe-plan-slider'
                  style={{height: '100vh'}}
                >
                  {pricingTable.map((pTable, index) => (
                    <SwiperSlide key={index}>
                      <div className={`${index % 2 === 0 ? 'even-plan' : 'odd-plan'} py-3 h-100 even-plan`} key={index}>
                        <PlanCard
                          title={pTable.title}
                          planItems={pTable.planItems}
                          totalAmount={pTable.totalAmount}
                          type={pTable.type}
                        />
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              )}
            </div>
          </div>
        </div>
      <div className="container">
        <WeGuaranteeSatisfaction />
      </div>
      <Company />
      <ClaimYourFreeSetupStrategy />
    </>
  )
}
