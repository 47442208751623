import React, { useState } from 'react';
import './claim-setup-form.css';

export default function ClaimSetupForm() {
  const [formData, setFormData] = useState({
    companyName: '',
    name: '',
    jobTitle: '',
    email: '',
    phone: '',
    describe: ''
  });

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [id]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormData({
      companyName: '',
      name: '',
      jobTitle: '',
      email: '',
      phone: '',
      describe: ''
    });
  };

  return (
    <>
      <div className="claim-setup-form py-5 px-2 px-lg-4 border-rounded-10 bg-white">
        <h5 className="default-md-title extra-primary-color text-center font-poppins-bold pb-3">
          Claim Your Free Setup Strategy <span className='d-block'> ($500 Value)</span>
        </h5>
        <form onSubmit={handleSubmit}>
          <div className="form-group mb-4">
            <label className='mb-2 default-special-md-description' htmlFor="companyName">Company*</label>
            <input
              type="text"
              className="form-control"
              id="companyName"
              placeholder="Enter company name"
              value={formData.companyName}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-row row">
            <div className="form-group mb-4 col-12 col-lg-6">
              <label className='mb-2 default-special-md-description' htmlFor="name">Name*</label>
              <input
                type="text"
                className="form-control"
                id="name"
                placeholder="Enter name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group mb-4 col-12 col-lg-6">
              <label className='mb-2 default-special-md-description' htmlFor="jobTitle">Job Title*</label>
              <input
                type="text"
                className="form-control"
                id="jobTitle"
                placeholder="Enter Job Title"
                value={formData.jobTitle}
                onChange={handleChange}
                required
              />
            </div>
          </div>
          <div className="form-row row">
            <div className="form-group mb-4 col-12 col-lg-6">
              <label className='mb-2 default-special-md-description' htmlFor="email">Email*</label>
              <input
                type="email"
                className="form-control"
                id="email"
                placeholder="Enter email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group mb-4 col-12 col-lg-6">
              <label className='mb-2 default-special-md-description' htmlFor="phone">Phone*</label>
              <input
                type="text"
                className="form-control"
                id="phone"
                placeholder="Enter phone number"
                value={formData.phone}
                onChange={handleChange}
                required
              />
            </div>
          </div>
          <div className="form-group mb-4">
            <label className='mb-2 default-special-md-description' htmlFor="describe">Describe your project requirements*</label>
            <textarea
              className="form-control"
              id="describe"
              rows="5"
              placeholder='Type here....'
              value={formData.describe}
              onChange={handleChange}
              required
            ></textarea>
          </div>
          <div className="form-group">
            <button type="submit" className="text-white border-0 primary-bg w-100 font-poppins-medium p-3 rounded">Send</button>
          </div>
        </form>
      </div>
    </>
  );
}
