import "./publisher.css";
import image from "../../../assets/images/outbound-publisher-audience-development/publisher-image.svg";
import { pointsList } from "../../../utils/outbound-publisher-audience-development/Data";
import Img from "../image/Img";
import Button from "../button/Button";
import SurveysAndMarket from "../surveys-and-market/SurveysAndMarket";

function Publisher() {
  return (
    <>
      <div className="col-12">
        <h2 className="my-4 custom-dark-color font-poppins-bold default-extra-special-xl-title">Publisher</h2>
      </div>
      <div className="col-12 primary-bg-light border-rounded-10">
        <div className="row p-4">
          <div className="col-12 col-lg-2 justify-content-center align-items-baseline d-flex">
            <Img src={image} width={'65px'} height={'65px'} alt={'Link Icon'} className={'img-fluid'} />
          </div>
          <div className="col-12 c-text-center">
            <h5 className="default-lg-description font-poppins-semi-bold custom-dark-color">
              The Contact Center Received the ICompli partner program
              certification by BPA World Wide
            </h5>
            <p className="default-md-description-extra">
              The Contact Center has been servicing the publishing industry
              faithfully and exceptionally since 1991. Moving forward, our
              clients can expect to reduce the time, effort and resources
              needed to conduct their BPA audits.
            </p>
            <div className="col-12  col-lg-5">
              <Button text={'View Certificate'} className={'btn primary-btn mt-0 w-100'} type={'button'} />
            </div>
          </div>
        </div>
      </div>
      <div className="col-12 point mt-4">
        <p className="default-md-description-extra">Serving the Publishing industry since 1991.</p>
        <p className="default-md-description-extra">BPA/AAM/VAC Audits = 100% success.</p>
        <div className="bullet-point-list">
          <SurveysAndMarket pointsList={pointsList} allowEmptyBullet={true} />
        </div>
      </div>
    </>
  );
}

export default Publisher;
