import React from 'react'
import Img from '../../common/components/image/Img'
import WhatsappForward from '../../assets/images/home/whatsapp-forward.svg'
import './outbound.css'
import { outboundServices } from '../../utils/outbound-marketing/Data'
import OutboundService from '../../common/components/outbound-service/OutboundService'
import Underline from '../../common/components/underline/Underline'
import SEO from '../../common/components/ui/seo/SEO'

const OutboundMarketingPage = () => {
  return (
    <>
      <SEO
        title='Outbound Services'
        description='Outbound Marketing With You in Mind.'
        name='The Contact Center.'
        type='process' />
      <div className="container">
        <Underline className={''}/>
      </div>
      <div className='container'>
        {/* Outbound Services start */}
        <div className="py-0 py-md-5 outbound-service-main-section position-relative text-center overflow-hidden">
          <div className='py-4 py-lg-5'>
            <h1 className='font-poppins-bold custom-dark-color text-center mb-0 default-xl-title d-flex justify-content-center align-items-center'>
              <Img src={WhatsappForward} width={'48px'} height={'48px'} alt={'Whatsapp'} className={'me-3 outbound-service-whatsapp'}/>
              <span>
                Outbound Services
              </span>
            </h1>
          </div>
        </div>
        <Underline className={''}/>
        {/* Outbound Services end */}

        {/* Our Process start */}
        <div className="our-process-section py-4 py-md-5">
          <div className="text-center px-xl-5 mx-xl-5">
            <p className="font-poppins-semi-bold mb-1 default-md-description">Our Process</p>
            <h2 className="font-poppins-bold custom-dark-color mb-lg-3 default-lg-title">Outbound Marketing With You in Mind<span className='primary-color'>.</span></h2>
            <div className="px-lg-5">
              <p className='default-sm-description px-lg-5 mx-lg-5'>In addition to lead generation services like appointment setting and telemarketing, we work on the customer service side as well with programs like market research and outbound surveys.</p>
            </div>
          </div>
          {outboundServices.map((obService, index) => (
            <div key={index}>
              <OutboundService
                key={index}
                title={obService.title}
                description={obService.description}
                url={obService.url}
                icon={obService.icon}
                Rectangle={obService.Rectangle}
              />
              <Underline className={'d-none d-md-block'}/>
            </div>
          ))}
        </div>
        {/* Our Process end */}

      </div>
    </>
  )
}

export default OutboundMarketingPage
