import React from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css/pagination';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import './home.css'
import Jumbotron from '../../common/components/jumbotron/Jumbotron';
import Underline from '../../common/components/underline/Underline';
import Service from "../../common/components/service/Service";
import HowWeDoIt from "../../common/components/how-we-do-it/HowWeDoIt";
import Slider from "../../common/components/Slider/Slider";
import { ourServices, howToDoIt, testimonials } from '../../utils/home/Data'
import SEO from "../../common/components/ui/seo/SEO";

const HomePage = () => {
  return (
    <>
      <SEO
        title='Home'
        description='Your Growth Catalyst in Inbound and Outbound Excellence.'
        name='The Contact Center.'
        type='services' />
    <div className="pb-5 pb-md-0">
      <div className="container">
        <Underline className={'d-none d-sm-block'}/>
      </div>
      <Jumbotron />
      {/* Our Services start */}
      <div className="container">
        <div className="mx-2 mx-xl-5 px-0 px-xl-5 pt-0 pt-xl-5">
          <div className="text-center pb-xl-5">
            <p className="font-poppins-semi-bold mb-1 default-md-description">Our Services</p>
            <h2 className="font-poppins-bold custom-dark-color mb-lg-5 default-lg-title"> <span className='primary-color'>One-Stop</span> Contact Center Solutions.</h2>
          </div>
          <div className="row our-service-section position-relative">
            {ourServices.map((service, index) => (
              <div className={`col-12 col-lg-8 col-xl-6 offset-0 offset-xl-0 ${service.offSet}`} key={index}>
                <Service
                  title={service.title}
                  description={service.description}
                  url={service.url}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* Our Services end */}

      {/* Our How We Do It start */}
      <div className="container how-we-do-it-main-section position-relative">
        <div className="pt-0 pt-xl-5">
          <div className="text-center pb-3 mx-2 mx-md-5 mx-lg-5 px-0 px-xl-5 how-we-do-it-section position-relative pt-lg-5">
            <p className="font-poppins-semi-bold mb-1 default-md-description">How We Do It</p>
            <h2 className="font-poppins-bold custom-dark-color mb-lg-5 px-0 px-lg-3 default-lg-title"> Generating <span className='primary-color'>actionable leads</span> and unlocking opportunities for your brand.</h2>
          </div>
          <div className="how-to-do-it">
            {howToDoIt.map((doIt, index) => (
              <HowWeDoIt
                key={index}
                title={doIt.title}
                description={doIt.description}
                imageUrl={doIt.imageUrl}
                imageRectangle={doIt.imageRectangle}
                linksData={doIt.linksData}
                displayItem={doIt.displayItem}
                changeDirection={doIt.changeDirection}
              />
            ))}
          </div>
        </div>
      </div>
      {/* Our How We Do It end */}

      {/* Our How We Do It start */}
      <div className="container mb-4 mb-lg-5 home-testimonial-section position-relative">
        <div className="pt-0 pt-xl-5 pb-0 pb-xl-5">
          <div className="text-center mx-1 mx-md-5 mx-lg-5 px-0 px-lg-5">
            <p className="font-poppins-semi-bold mb-1 default-md-description">Testimonials</p>
            <h2 className="font-poppins-bold custom-dark-color mb-xl-5 default-lg-title"> We Guarantee <span className='primary-color'>Satisfaction.</span></h2>
          </div>
          <Swiper
            slidesPerView={1}
            spaceBetween={10}
            pagination={{
              clickable: true,
            }}
            breakpoints={{
              767: {
                slidesPerView: 1,
                spaceBetween: 30,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 30,
              },
              1100: {
                slidesPerView: 3,
                spaceBetween: 30,
              },
            }}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            navigation={true}
            modules={[Autoplay, Pagination, Navigation]}
            loop={true}
            className=""
          >
            {testimonials.map((testimonial, index) => (
              <SwiperSlide key={index}>
                <Slider
                  name={testimonial.name}
                  role={testimonial.role}
                  profileImage={testimonial.profileImage}
                  description={testimonial.description}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
      {/* Our How We Do It end */}
    </div>
    </>
  );
};

export default HomePage;
