import React from 'react'
import Img from '../image/Img'
import './slider.css'
import { RatingBar } from '../RatingBar/index'

export default function Slider({name, role, profileImage, description}) {
  return (
    <>
      <div className="card rating-card mx-1 mx-md-0 p-2 border-rounded-10">
        <div className="card-body row">
          <div className="col-12">
            <div className='d-flex justify-content-end'>
            <RatingBar
              className="flex justify-between w-[115px]"
              value={5}
              starCount={5}
              activeColor="#ffb700"
              size={19}
            ></RatingBar>
            </div>
            <div className="d-flex align-content-center mb-4">
              <div className="me-3">
                <Img src={profileImage} width={''} height={''} alt={'Profile image'} className={''} />
              </div>
              <div>
                <h5 className="card-title custom-dark-color font-poppins-semi-bold mb-0 default-md-description-extra">{name}</h5>
                <small className='d-block'>{role}</small>
              </div>
            </div>
            <p className="card-text mb-3 default-md-description-extra">{description}</p>
          </div>
        </div>
      </div>
    </>
  )
}
