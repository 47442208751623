import React from 'react'
import ContentMarketing from '../../common/components/content-marketing/ContentMarketing'
import ClaimSetupForm from '../../common/components/claim-setup-form/ClaimSetupForm'
import './outbound-content-marketing-distribution.css'
import DistributionStrategy from '../../common/components/distribution-strategy/DistributionStrategy'
import { yourContentMarketing, contentMarketing, contentMarketingStrategies } from '../../utils/outbound-content-marketing-distribution/Data'
import ContentItemCard from '../../common/components/content-item-card/ContentItemCard'
import WeGuaranteeSatisfaction from '../../common/components/we-guarantee-satisfaction/WeGuaranteeSatisfaction'
import Company from '../../common/components/company/Company'
import ClaimYourFreeSetupStrategy from '../../common/components/claim-your-free-setup-strategy/ClaimYourFreeSetupStrategy'
import Underline from '../../common/components/underline/Underline'
import SEO from '../../common/components/ui/seo/SEO'

export default function OutboundContentMarketingDistributionPage() {
  return (
    <>
      <SEO
        title='Content Marketing Distribution'
        description='Generate Quality Leads with Better Content Marketing Distribution Strategies. Unlocking New Business opportunities for Our Clients Since 1991'
        name='The Contact Center.'
        type='Marketing' />
      <Underline className={'d-block d-sm-none mt-3'}/>
      <div className="container">
        <div className="row py-2 py-lg-5 my-xl-5">
          <div className="col-12 col-lg-6">
            <ContentMarketing contentMarketing={contentMarketing}/>
          </div>
          <div className="col-12 col-lg-6">
            <div className="claim-setup-form-section pt-4 pt-lg-0 ps-xl-3">
              <ClaimSetupForm />
            </div>
          </div>
        </div>
        <div className='mt-5'>
          <DistributionStrategy contentMarketingStrategies={contentMarketingStrategies}/>
        </div>
      </div>
      <div className="py-5 primary-bg-light">
        <div className="container">
          <div className="row generate-quality-leads position-relative">
            <div className="col-12 col-lg-6 c-text-center">
              <h2 className="font-poppins-bold custom-dark-color default-special-xl-title">
                Lower your  <span className='primary-color c-custom-dark-color'>content marketing </span> spend by as much as 50%
              </h2>
              <p className="font-poppins-semi-bold mb-1 default-lg-description mb-3">Generate Quality Leads with Better Content Marketing Distribution Strategies.</p>
              <p className='default-special-description'>We ensure that your content is delivered and received by your target audience. Using our technology we can continue to track these people “digital behavior” to asses their degree of interest in your products/services. Moreover, we can invite the potential customer to ask questions or take action that leads to a faster sale. During the conversation, our representatives can gather critical information about the potential customer’s interest in your company and the likelihood that they will convert to a paying client.</p>
            </div>
            <div className="col-12 col-lg-6">
              <div className="row">
                {yourContentMarketing.map((contentItem, index) => (
                  <div className={`col-8 col-md-6 ${index % 2 === 1 ? 'offset-4 offset-md-0' : ''} ${index === yourContentMarketing.length - 1 ? 'm-md-auto' : ''}`} key={index}>
                    <ContentItemCard
                      key={index}
                      icon={contentItem.icon}
                      link={contentItem.link}
                      data={contentItem.data}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container bg-white position-relative">
        <WeGuaranteeSatisfaction />
      </div>
      <Company />
      <ClaimYourFreeSetupStrategy />
    </>
  )
}
