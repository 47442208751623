import WhatsappForward from '../../assets/images/home/whatsapp-forward.svg';
import ContactUser from '../../assets/images/home/contact-user.svg';
import Desktop from '../../assets/images/home/desktop.svg';
import CallCenter from '../../assets/images/home/image 40.png';
import Marketing from '../../assets/images/home/image 39.png';
import Industries from '../../assets/images/home/image 43.png';
import InboundMarketing from '../../assets/images/home/image 44.png';
import Rectangle from '../../assets/images/home/Rectangle.jpg';
import ArrowRight from '../../assets/images/svg/arrow-right.svg';
import MichaelProfile from '../../assets/images/slider/image 17.png';
import AnnaProfile from '../../assets/images/slider/image 20.png';
import JohnProfile from '../../assets/images/slider/image 21.png';



export const ourServices = [
  {
    title: 'Inbound Services',
    description: 'Ensure 24/7 customer support through our inbound outsourcing services—be there when customers reach out, with response via telephone, live chat, text, and email.',
    url: WhatsappForward,
    offSet: ''
  },
  {
    title: 'Outbound Services',
    description: 'From lead generation to Content Marketing Distribution, we support clients with customer development, winback programs, market research, and outbound surveys.',
    url: WhatsappForward,
    offSet: 'offset-lg-4'
  },
  {
    title: 'Lead Generation',
    description: 'Qualifying leads since 1991, The Contact Center. has served companies like DELL, HP, Motorola, SAP, KnowB4, and Code 42 with expertise and precision.',
    url: ContactUser,
    offSet: ''
  },
  {
    title: 'Content Marketing',
    description: 'Success hinges on selecting optimal channels for content distribution. Our process ensures timely engagement from decision-makers and influencers.',
    url: Desktop,
    offSet: 'offset-lg-4'
  }
]

export const howToDoIt = [
  {
    title: 'Call Center Solutions',
    description: 'With inbound marketing services from The Contact Center , your prospects and customers receive the same level of care that they have grown accustomed to receiving from you.',
    imageUrl: CallCenter,
    imageRectangle: Rectangle,
    displayItem: false,
    changeDirection: false,
    linksData:[
      {
        link: '/outbound-marketing',
        content: '24/7 Call Center Services',
        icon: ArrowRight
      },
      {
        link: '/outbound-marketing',
        content: 'Shared & Cost Effective Setups',
        icon: ArrowRight
      },
      {
        link: '/outbound-marketing',
        content: 'US Based American Setup',
        icon: ArrowRight
      },
      {
        link: '/outbound-marketing',
        content: '24hr Customer Support for Small Business',
        icon: ArrowRight
      },
      {
        link: '/outbound-marketing',
        content: 'Dedicated Branded Support',
        icon: ArrowRight
      },
      {
        link: '/outbound-marketing',
        content: 'Automation + Live Operator Support',
        icon: ArrowRight
      },
      {
        link: '/outbound-marketing',
        content: 'Call Center Services Near Me',
        icon: ArrowRight
      },
      {
        link: '/outbound-marketing',
        content: 'Bilingual / Spanish Call Center Services',
        icon: ArrowRight
      },
    ]
  },
  {
    title: 'Outbound Marketing',
    description: 'Our outbound services have generated tens of millions of dollars in new business for technology, manufacturing, and service companies. We set up nurturing processes to secure our clients’ return on investment.',
    imageUrl: Marketing,
    imageRectangle: Rectangle,
    displayItem: false,
    changeDirection: true,
    linksData:[
      {
        link: '/outbound-marketing',
        content: 'Call Center Outsourcing',
        icon: ArrowRight
      },
      {
        link: '/outbound-marketing',
        content: 'Quality B2B Lists to Grow Your Business',
        icon: ArrowRight
      },
      {
        link: '/outbound-marketing',
        content: 'B2B Telemarketing',
        icon: ArrowRight
      },
      {
        link: '/outbound-marketing',
        content: 'Customer Development & Winback',
        icon: ArrowRight
      },
      {
        link: '/outbound-marketing',
        content: 'Lead Generation',
        icon: ArrowRight
      },
      {
        link: '/outbound-marketing',
        content: 'Content Marketing Distribution',
        icon: ArrowRight
      },
      {
        link: '/outbound-marketing',
        content: 'Surveys & Marketing Research',
        icon: ArrowRight
      }
    ]
  },
  {
    title: 'Industries We Serve',
    description: 'We offer customer service, sales support and marketing services to a diverse set of channels and industries.',
    imageUrl: Industries,
    imageRectangle: Rectangle,
    displayItem: true,
    changeDirection: false,
    linksData:[
      {
        link: '/outbound-marketing',
        content: 'Automative',
        icon: ArrowRight
      },
      {
        link: '/outbound-marketing',
        content: 'Hospitality',
        icon: ArrowRight
      },
      {
        link: '/outbound-marketing',
        content: 'Telecom',
        icon: ArrowRight
      },
      {
        link: '/outbound-marketing',
        content: 'B2b',
        icon: ArrowRight
      },
      {
        link: '/outbound-marketing',
        content: 'Insurance',
        icon: ArrowRight
      },
      {
        link: '/outbound-marketing',
        content: 'Utilities',
        icon: ArrowRight
      },
      {
        link: '/outbound-marketing',
        content: 'Contractors',
        icon: ArrowRight
      },
      {
        link: '/outbound-marketing',
        content: 'Legal',
        icon: ArrowRight
      },
      {
        link: '/outbound-marketing',
        content: 'Healthcare',
        icon: ArrowRight
      },
      {
        link: '/outbound-marketing',
        content: 'Ecommerce',
        icon: ArrowRight
      },
      {
        link: '/Nonprofit',
        content: 'Technology',
        icon: ArrowRight
      },
      {
        link: '/outbound-marketing',
        content: 'Education',
        icon: ArrowRight
      },
      {
        link: '/outbound-marketing',
        content: 'Political',
        icon: ArrowRight
      },
      {
        link: '/outbound-marketing',
        content: 'Small Business',
        icon: ArrowRight
      },
      {
        link: '/outbound-marketing',
        content: 'Financial',
        icon: ArrowRight
      },
      {
        link: '/outbound-marketing',
        content: 'Publishing',
        icon: ArrowRight
      },
      {
        link: '/outbound-marketing',
        content: 'Government',
        icon: ArrowRight
      },
      {
        link: '/outbound-marketing',
        content: 'Franchise',
        icon: ArrowRight
      },
      {
        link: '/outbound-marketing',
        content: 'Retail',
        icon: ArrowRight
      },
    ]
  },
  {
    title: 'Inbound Marketing',
    description: 'With inbound marketing services from The Contact Center , your prospects and customers receive the same level of care that they have grown accustomed to receiving from you.',
    imageUrl: InboundMarketing,
    imageRectangle: Rectangle,
    displayItem: false,
    changeDirection: true,
    linksData:[
      {
        link: '/outbound-marketing',
        content: 'Omnichannel',
        icon: ArrowRight
      },
      {
        link: '/outbound-marketing',
        content: '24hr Email Response',
        icon: ArrowRight
      },
      {
        link: '/outbound-marketing',
        content: 'Business Call Screening',
        icon: ArrowRight
      },
      {
        link: '/outbound-marketing',
        content: '24hr Live Emergency',
        icon: ArrowRight
      },
      {
        link: '/outbound-marketing',
        content: 'Call Center Outsourcing',
        icon: ArrowRight
      },
      {
        link: '/outbound-marketing',
        content: 'Conference & Event Registration',
        icon: ArrowRight
      },
      {
        link: '/outbound-marketing',
        content: 'Customer Service Outsourcing',
        icon: ArrowRight
      },
      {
        link: '/outbound-marketing',
        content: 'Help Disk',
        icon: ArrowRight
      },
      {
        link: '/outbound-marketing',
        content: 'Direct Response',
        icon: ArrowRight
      },
      {
        link: '/outbound-marketing',
        content: 'Live Chat Outsourcing',
        icon: ArrowRight
      },
      {
        link: '/outbound',
        content: 'Order Processing',
        icon: ArrowRight
      },
    ]
  },
]
export const testimonials = [
  {
    name: 'Michael Kalzer',
    role:'CEO',
    profileImage: MichaelProfile,
    description:'“Business is all about solving people’s problems — at a profit.”'
  },
  {
    name: 'Anna Leo',
    role:'CEO',
    profileImage: AnnaProfile,
    description:'“Business is all about solving people’s problems — at a profit.”'
  },
  {
    name: 'John Mark',
    role:'CEO',
    profileImage: JohnProfile,
    description:'“Business is all about solving people’s problems — at a profit.”'
  },
  {
    name: 'Michael Kalzer',
    role:'CEO',
    profileImage: MichaelProfile,
    description:'“Business is all about solving people’s problems — at a profit.”'
  }
]
