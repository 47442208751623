import React from 'react'
import ClaimSetupForm from '../../common/components/claim-setup-form/ClaimSetupForm'
import ContentMarketing from '../../common/components/content-marketing/ContentMarketing'
import { generatingTargetedLeads, contentMarketingStrategies, sectionContent } from '../../utils/industries-automotive/Data'
import DistributionStrategy from '../../common/components/distribution-strategy/DistributionStrategy'
import WeGuaranteeSatisfaction from '../../common/components/we-guarantee-satisfaction/WeGuaranteeSatisfaction'
import Company from '../../common/components/company/Company'
import ClaimYourFreeSetupStrategy from '../../common/components/claim-your-free-setup-strategy/ClaimYourFreeSetupStrategy'
import ContentSection from '../../common/components/content-section/ContentSection'
import SEO from '../../common/components/ui/seo/SEO'


export default function IndustriesAutomotivePage() {
  return (
    <>
      <SEO
        title='Automotive Call Center Services'
        description='The Contact Center Marketing is a multichannel inbound contact center, which means convenience and flexibility for your customers. They choose their preferred method for getting in contact with you, including voice, text, email and messaging. In addition, it’s easy to scale services up or down depending on promotions or seasonality.'
        name='Covering Promotions, Capturing Leads'
        type='Cultivating' />
      <div className="container">
        <div className="row py-2 py-lg-5 my-xl-5">
          <div className="col-12 col-lg-6">
            <ContentMarketing contentMarketing={generatingTargetedLeads}/>
          </div>
          <div className="col-12 col-lg-6">
            <div className="claim-setup-form-section-leads position-relative">
              <ClaimSetupForm />
            </div>
          </div>
        </div>
      </div>
      <div className="we-guarantee-satisfaction position-relative">
        <div className="pt-0 container">
          <DistributionStrategy contentMarketingStrategies={contentMarketingStrategies}/>
        </div>
      </div>
      <div className="text-center py-5 mt-5 primary-bg-light">
        <ContentSection sectionContent={sectionContent}/>
      </div>
      <div className="container">
        <WeGuaranteeSatisfaction />
      </div>
      <Company />
      <ClaimYourFreeSetupStrategy />
    </>
  )
}
