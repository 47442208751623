import React from 'react'
import ClaimSetupForm from '../../common/components/claim-setup-form/ClaimSetupForm'
import ContentMarketing from '../../common/components/content-marketing/ContentMarketing'
import { generatingTargetedLeads, contentMarketingStrategies } from '../../utils/outbound-political-campaign/Data'
import Button from '../../common/components/button/Button'
import DistributionStrategy from '../../common/components/distribution-strategy/DistributionStrategy'
import WeGuaranteeSatisfaction from '../../common/components/we-guarantee-satisfaction/WeGuaranteeSatisfaction'
import Company from '../../common/components/company/Company'
import ClaimYourFreeSetupStrategy from '../../common/components/claim-your-free-setup-strategy/ClaimYourFreeSetupStrategy'
import SEO from '../../common/components/ui/seo/SEO'


export default function OutboundPoliticalCampaignPage() {
  return (
    <>
      <SEO
        title='Political Call Center Services'
        description='The Contact Center  is a multichannel inbound contact center, which means convenience and flexibility for your customers. They choose their preferred method for getting in contact with you, including voice, text, email and messaging. In addition, it’s easy to scale services up or down depending on promotions or seasonality.'
        name='Streamline Your Call Center Efforts'
        type='contact center efforts' />
      <div className="container">
        <div className="row py-2 py-lg-5 my-xl-5">
          <div className="col-12 col-lg-6">
            <ContentMarketing contentMarketing={generatingTargetedLeads}/>
          </div>
          <div className="col-12 col-lg-6">
            <div className="claim-setup-form-section-leads position-relative">
              <ClaimSetupForm />
            </div>
          </div>
        </div>
      </div>
      <div className="we-guarantee-satisfaction position-relative">
        <div className="pt-0 container">
          <DistributionStrategy contentMarketingStrategies={contentMarketingStrategies}/>
        </div>
      </div>
      <div className="text-center py-5 mt-5 primary-bg-light">
        <div className="container px-xl-5">
          <h2 className="font-poppins-bold custom-dark-color mb-3 default-extra-special-xl-title">
            Expand your <span className='primary-color c-custom-dark-color'>voter contact </span> center efforts
          </h2>
            <p className="mb-4 default-lg-description font-poppins-semi-bold px-xl-5 mx-xl-4">We will work with you to effectively create a blended strategy that will optimize precision timing, serving as your partner in the decision-making process.</p>
          <div className="px-xl-2">
            <p className="mb-4 default-md-description-extra">If you’ve worked with other telemarketing firms in the past, you know that keeping several firms at your disposal ensures a more successful campaign. We will work with you to effectively create a blended strategy that will optimize precision timing, serving as your partner in the decision-making process. With our quick turnaround time, on-time delivery and robust reporting, you will quickly see the value in your The Contact Center  investment.</p>
            <p className="mb-4 default-md-description-extra">Take advantage of our live agent support today to expand your voter contact center efforts. And don’t forget to ask your The Contact Center  political team leader how we can develop a customized program for you. We’re standing by to help you today.</p>
            <div className="pt-4">
              <Button text={'Let’s Connect'} className={'btn primary-btn mt-0'} type={'button'} />
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <WeGuaranteeSatisfaction />
      </div>
      <Company />
      <ClaimYourFreeSetupStrategy />
    </>
  )
}
