import React from 'react'
import ClaimSetupForm from '../../common/components/claim-setup-form/ClaimSetupForm'
import ContentMarketing from '../../common/components/content-marketing/ContentMarketing'
import { generatingTargetedLeads, weSpecialize, whyShouldIOutsourceMyCustomerService, isItCostEffectiveToOutSourceMyCustomerService } from '../../utils/inbound-customer-services/Data'
import Button from '../../common/components/button/Button'
import WeGuaranteeSatisfaction from '../../common/components/we-guarantee-satisfaction/WeGuaranteeSatisfaction'
import Company from '../../common/components/company/Company'
import ClaimYourFreeSetupStrategy from '../../common/components/claim-your-free-setup-strategy/ClaimYourFreeSetupStrategy'
import ImageCard from '../../common/components/image-card/ImageCard'
import './inbound-customer-services.css'
import SEO from '../../common/components/ui/seo/SEO'


export default function InboundCustomerServicesPage() {
  return (
    <>
      <SEO
        title='Customer Service Outsourcing'
        description='The Contact Center  creates tailored teams of outstanding customer service experts available around the clock who are proficient using not only phone communications, but also e-mail, chat, text and digital and social media channels.'
        name='We specialize in rapidly assembling well-versed customer experience'
        type='Support requirements' />
      <div className="container">
        <div className="row py-2 py-lg-5 my-xl-5">
          <div className="col-12 col-lg-6">
            <ContentMarketing contentMarketing={generatingTargetedLeads}/>
          </div>
          <div className="col-12 col-lg-6">
            <div className="claim-setup-form-section-leads position-relative">
              <ClaimSetupForm />
            </div>
          </div>
        </div>
      </div>
      <div className="text-center py-5 primary-bg-light px-lg-4">
        <div className="container">
          <h2 className="font-poppins-bold custom-dark-color">
            We specialize in rapidly assembling well-versed customer experience teams aligned with your brand, industry, and support requirements.
          </h2>
          {weSpecialize.map((weSpecial, index) => (
            <p className='default-description'>{weSpecial.listItem}</p>
          ))}
         <div className="pt-4">
            <Button text={'Let’s Connect'} className={'btn primary-btn mt-0'} type={'button'} />
          </div>
        </div>
      </div>
      <div className="text-center pt-5">
        <div className="container px-0">
          {whyShouldIOutsourceMyCustomerService.map((whyShould, index) => (
            <>
              <h2 className="font-poppins-bold custom-dark-color">
                {whyShould.title}
              </h2>
              <p className='mb-5 default-description'>{whyShould.description}</p>
              <div className="row inbound-customer-services-items-section justify-content-center">
                {whyShould.ourServices.map((ourService, index) => (
                  <div className="col-12 col-lg-4 col-xl-3" key={index}>
                    <ImageCard
                      key={index}
                      title={ourService.title}
                      description={ourService.description}
                      url={ourService.url}
                    />
                  </div>
                ))}
              </div>
            </>
          ))}
          <div className="py-4">
            <Button text={'Let’s Connect'} className={'btn primary-btn mt-0'} type={'button'} />
          </div>
        </div>
      </div>
      <div className="text-center py-5">
        <div className="container px-0">
          {isItCostEffectiveToOutSourceMyCustomerService.map((isItCostEffective, index) => (
            <>
              <h2 className="font-poppins-bold custom-dark-color">
                {isItCostEffective.title}
              </h2>
              <p className='mb-5 default-description mx-lg-5 px-lg-5'>{isItCostEffective.description}</p>
              <div className="row inbound-customer-services-items-section-service justify-content-center">
                {isItCostEffective.ourServices.map((ourService, index) => (
                  <div className="col-12 col-lg-4 col-xl-3" key={index}>
                    <ImageCard
                      key={index}
                      title={ourService.title}
                      description={ourService.description}
                      url={ourService.url}
                    />
                  </div>
                ))}
              </div>
            </>
          ))}
          <p className='mx-lg-5 px-lg-5 default-description'>To mitigate these challenges, it’s crucial to select a reputable and reliable outsourcing partner, establish clear communication channels, implement robust data security protocols, and regularly monitor and evaluate the performance of the outsourcing provider to ensure that the customer service standards align with your business objectives and values.</p>
          <div className="py-4">
            <Button text={'Let’s Connect'} className={'btn primary-btn mt-0'} type={'button'} />
          </div>
        </div>
      </div>
      <div className="container">
        <WeGuaranteeSatisfaction />
      </div>
      <Company />
      <ClaimYourFreeSetupStrategy />
    </>
  )
}
