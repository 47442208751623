import React from 'react'
import './jumbotron.css'
import Button from '../button/Button'
import Img from '../image/Img'
import JumbotronImage from '../../../assets/images/home/jumbotron-image.png'

export default function Jumbotron() {
  return (
    <>
    <div className="container position-relative pb-4 my-3 my-xl-5 pb-xl-5">
      <section className='jumbotron-section position-relative'>
        <Img src={JumbotronImage} width={'100%'} height={'100%'} alt={'Logo'} className={'jumbotron-section-image position-relative'} />
        <div className="content-area text-white">
          <h1 className='font-poppins-bold'>Your Growth Catalyst in Inbound and Outbound Excellence.</h1>
          <p className='mb-0 font-poppins-medium default-sm-description'>With over three decades of proven success, we specialize in tailored solutions that elevate your sales, enhance brand quality, and secure increased revenue. Count on us for unwavering authority and reliability, guiding your business towards unparalleled success.</p>
          <div className="cta mt-3 mt-lg-4">
            <Button text={'Get Started'} className={'btn primary-btn mt-0'} type={'button'} />
          </div>
        </div>
      </section>
    </div>
    </>
  )
}
