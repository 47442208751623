import React from 'react'
import Button from '../button/Button'
import './claim-your-free-setup-strategy.css'

export default function ClaimYourFreeSetupStrategy() {
  return (
    <>
      <div className="py-4 py-lg-5 primary-bg-light text-center claim-your-free-setup-strategy-section position-relative">
        <div className="container">
          <h2 className="font-poppins-bold extra-primary-color mb-4 mb-lg-5 default-lg-title">
            Claim Your Free Setup Strategy
            <span className="d-md-block">($500 Value)</span>
          </h2>
          <Button text={'Lets Connect'} className={'btn primary-btn mt-0 mb-2 mb-md-0'} type={'button'} />
        </div>
      </div>
    </>
  )
}
