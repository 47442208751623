import React from 'react'
import ClaimSetupForm from '../../common/components/claim-setup-form/ClaimSetupForm'
import ContentMarketing from '../../common/components/content-marketing/ContentMarketing'
import { generatingTargetedLeads, contentMarketingStrategies, sectionContent } from '../../utils/industries-insurance/Data'
import DistributionStrategy from '../../common/components/distribution-strategy/DistributionStrategy'
import WeGuaranteeSatisfaction from '../../common/components/we-guarantee-satisfaction/WeGuaranteeSatisfaction'
import Company from '../../common/components/company/Company'
import ClaimYourFreeSetupStrategy from '../../common/components/claim-your-free-setup-strategy/ClaimYourFreeSetupStrategy'
import ContentSection from '../../common/components/content-section/ContentSection'
import SEO from '../../common/components/ui/seo/SEO'

export default function IndustriesInsurancePage() {
  return (
    <>
      <SEO
        title='Outsourced Insurance Call Centers'
        description='When it comes to insurance, the customer experience defines your brand. A customer that encounters a friendly, helpful agent that is focused on the first-call resolution of their questions or challenges is a customer that will tell their friends and family about your agency.'
        name='Outsourced Insurance Call Centers'
        type='Outsourced Insurance' />
      <div className="container">
        <div className="row py-2 py-lg-5 my-xl-5">
          <div className="col-12 col-lg-6">
            <ContentMarketing contentMarketing={generatingTargetedLeads}/>
          </div>
          <div className="col-12 col-lg-6">
            <div className="claim-setup-form-section-leads position-relative">
              <ClaimSetupForm />
            </div>
          </div>
        </div>
      </div>
      <div className="we-guarantee-satisfaction position-relative">
        <div className="pt-0 container">
          <DistributionStrategy contentMarketingStrategies={contentMarketingStrategies}/>
        </div>
      </div>
      <div className="text-center py-5 mt-5 primary-bg-light">
        <ContentSection sectionContent={sectionContent} />
      </div>
      <div className="container">
        <WeGuaranteeSatisfaction />
      </div>
      <Company />
      <ClaimYourFreeSetupStrategy />
    </>
  )
}
