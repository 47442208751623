import React from 'react'
import ClaimSetupForm from '../../common/components/claim-setup-form/ClaimSetupForm'
import ContentMarketing from '../../common/components/content-marketing/ContentMarketing'
import { generatingTargetedLeads, ActionableLeads, accordionData } from '../../utils/outbound-b2b-list-for-business-growth/Data'
import Button from '../../common/components/button/Button'
import Service from '../../common/components/service/Service'
import './outbound-b2b-list-for-business-growth.css'
import WeGuaranteeSatisfaction from '../../common/components/we-guarantee-satisfaction/WeGuaranteeSatisfaction'
import Company from '../../common/components/company/Company'
import Accordion from '../../common/components/accordion/Accordion'
import ClaimYourFreeSetupStrategy from '../../common/components/claim-your-free-setup-strategy/ClaimYourFreeSetupStrategy'
import SEO from '../../common/components/ui/seo/SEO'


export default function OutboundB2BListForBusinessGrowthPage() {

  return (
    <>
      <SEO
        title='Enhance Your Sales Pipeline and Grow Your Business with Quality B2B Lists containing 100% Verified E-mails'
        description='Accelerate the process of identifying new business opportunities and enhance your company’s growth with greater efficiency and reduced expenses. By utilizing The Contact Center ’s B2B contact data, you can eliminate the need to invest months in compiling business lists.'
        name='Prospecting just got simpler'
        type='CRM' />
      <div className="container">
        <div className="row py-2 py-lg-5 my-xl-5">
          <div className="col-12 col-lg-6">
            <ContentMarketing contentMarketing={generatingTargetedLeads}/>
          </div>
          <div className="col-12 col-lg-6">
            <div className="claim-setup-form-section-leads position-relative">
              <ClaimSetupForm />
            </div>
          </div>
        </div>
      </div>
      <div className="text-center py-5 mt-5 primary-bg-light">
        <div className="container">
          <h2 className="font-poppins-bold custom-dark-color mb-3 default-extra-special-xl-title">
            Prospecting just got  <span className='primary-color'> simpler</span>
          </h2>
          <div className="content-info-section px-lg-2">
            <p className="mb-4 default-description">B2B enterprises fully recognize the significance of high-quality leads. Whether it’s a fledgling startup or a well-established company with decades of expertise, acquiring qualified leads and effectively converting them into paying customers is an indispensable business process. To enhance their chances of success, every business requires access to contact information that aligns with their ideal client criteria.</p>
            <p className="mb-4 default-description">This is precisely where The Contact Center  steps in. Our comprehensive lists encompass 100,000,000 records across all industries. We guarantee the accuracy and verification of our data, enabling you to connect with key decision-makers, buyers, and influencers.</p>
            <p className="mb-4 default-description">Once your marketing and/or sales team gain access to our top-notch lists, their tasks become more streamlined. Our lists grant them entry into a reliable and human-verified pool of B2B contact data.</p>
            <div className="pt-4">
              <Button text={'Let’s Connect'} className={'btn primary-btn mt-0'} type={'button'} />
            </div>
          </div>
        </div>
      </div>
      <div className="text-center py-5 understand-the-individual-section">
        <div className="container">
            <h2 className="font-poppins-bold custom-dark-color mb-3 default-extra-special-xl-title">
              <span className='primary-color'>Understand </span>the Individual You're Engaging
            </h2>
            <div className="content-info-section">
              <p className="mb-4 default-description">When dealing with marketing lists, there are four essential factors that demand your attention:Industry/Company – The employer of the people you’re engaging with.Job Function – The specific position or role held by the person you’re engaging with.Contact Details – address, e-mail, SIC/NAICS Company size etc…SOLUTIONS – The specific problem or challenge that your product or service can assist them in resolving.</p>
              <p className="mb-4 default-description">
                <span className='w-100'>We can offer you the following data points included in each list:</span>
                The individuals you will contact are important decision-makers, buyers, or influencers within their respective companies, ideally holding top management positions. It is essential to have knowledge about the company itself, including its asset size, location, and the geographical region in which it operates. The specific problems that you can assist them in solving will differ based on the industry and market conditions.</p>
              <p className="mb-4 default-description">Our B2B lists compile these crucial details and present them to you in a convenient and easily understandable format.</p>
              <div className="pt-4">
                <Button text={'Let’s Connect'} className={'btn primary-btn mt-0'} type={'button'} />
              </div>
            </div>
        </div>
      </div>
      <div className="primary-bg-light py-5">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <h2 className="font-poppins-bold custom-dark-color mb-3 default-extra-special-xl-title">
                Simplify Your Journey from  <span className='primary-color'>Prospects to Buyers </span>
              </h2>
            </div>
            <div className="col-12 py-3">
              <div className="row simplify-section">
                {ActionableLeads.map((ActionableLead, index) => (
                  <div className={`col-12 col-lg-8 col-xl-6 offset-0 offset-xl-0 ${ActionableLead.offSet}`} key={index} >
                    <Service
                      title={ActionableLead.title}
                      description={ActionableLead.description}
                      url={ActionableLead.url}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="text-center py-5 d-none d-lg-block">
        <div className="container">
            <h2 className="font-poppins-bold custom-dark-color mb-3 default-extra-special-xl-title">
              Our<span className='primary-color'> Portfolio </span>Says It All
            </h2>
            <div className="content-info-section px-lg-2">
              <p className="mb-4 default-description">The Contact Center  has established itself as a reliable provider of B2B lists and qualified leads for numerous top companies in the industry. With a track record dating back to 1991, the company has consistently served esteemed clients such as Dell, Motorola, HP, SAP, and others.</p>
              <p className="mb-4 default-description">Over the course of three decades, The Contact Center  has expanded its client base and formed partnerships with renowned companies including Microsoft, IBM, Nvidia, Cisco, Salesforce, AWS, and Verizon, among others. These collaborations highlight the company’s ability to adapt to changing market demands and cater to the needs of diverse businesses.</p>
              <p className="mb-4 default-description">Despite the evolving clientele, The Contact Center ’s core objective has remained unwavering. The company strives to provide its clients with exceptional data and leads, enabling them to drive growth and achieve their business objectives effectively.</p>
              <p className="mb-4 default-description">Through their extensive experience and strong industry connections, The Contact Center  has positioned itself as a trusted partner in the B2B data and leads industry. Their portfolio of esteemed clients stands as a testament to their commitment to excellence and their dedication to helping businesses thrive.</p>
              <div className="pt-4">
                <Button text={'Let’s Connect'} className={'btn primary-btn mt-0'} type={'button'} />
              </div>
            </div>
        </div>
      </div>
      <div className="text-center py-5 primary-bg-light d-none d-lg-block">
        <div className="container px-0 px-lg-5">
          <h2 className="font-poppins-bold custom-dark-color mb-lg-5 default-lg-title">
            All Your Questions <span className='primary-color'> Answered</span>
          </h2>
          <Accordion accordionData={accordionData}/>
       </div>
      </div>
      <div className="container">
        <WeGuaranteeSatisfaction />
      </div>
      <Company />
      <ClaimYourFreeSetupStrategy />
    </>
  )
}
