import React from 'react'
import { ourServices, pointsList } from '../../utils/inbound-omnichannel/Data'
import Button from '../../common/components/button/Button'
import WeGuaranteeSatisfaction from '../../common/components/we-guarantee-satisfaction/WeGuaranteeSatisfaction'
import Company from '../../common/components/company/Company'
import ClaimYourFreeSetupStrategy from '../../common/components/claim-your-free-setup-strategy/ClaimYourFreeSetupStrategy'
import ImageCard from '../../common/components/image-card/ImageCard'
import LetsGrowYourAudienceForm from '../../common/components/lets-grow-your-audience-form/LetsGrowYourAudienceForm'
import SEO from '../../common/components/ui/seo/SEO'


export default function InboundOmniChannelPage() {
  return (
    <>
      <SEO
        title='Omnichannel'
        description='From chat to email and social media, The Contact Center. Inbound Contact Center Services allows you to professionally manage the flood of inquiries possible when your target market responds to an omnichannel strategy.'
        name='Omnichannel Services by The Contact Center. Contact Center Services'
        type='Chat' />
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-8">
            <h2 className='custom-dark-color font-poppins-bold mt-5 default-extra-special-xl-title'>Omnichannel</h2>
            <p className='default-lg-description font-poppins-semi-bold'>Omnichannel Services by The Contact Center. Contact Center Services</p>
            {pointsList.map((pointsList, index) => (
              <p key={index} className='default-md-description-extra'>{pointsList.listItem}</p>
            ))}
            <div className="row inbound-customer-services-items-section-omni-service justify-content-center py-2 py-lg-5">
              {ourServices.map((ourService, index) => (
                <div className="col-12 col-lg-6" key={index}>
                  <ImageCard
                    title={ourService.title}
                    description={ourService.description}
                    url={ourService.url}
                  />
                </div>
              ))}
              <div className="col-4 m-auto d-none d-lg-block py-2">
                <Button text={'Let’s Talk Channels'} className={'btn primary-btn mt-0 w-100'} type={'button'} />
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-4">
            <LetsGrowYourAudienceForm />
          </div>
          <div className="col-12 col-md-8">
            <Company slides={{ small: 4, medium: 4, large: 4, xLarge: 5 }} />
            <WeGuaranteeSatisfaction smallScreen={true} />
          </div>
        </div>
      </div>
      <div className='d-sm-none'>
        <ClaimYourFreeSetupStrategy />
      </div>
    </>
  )
}

