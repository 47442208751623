import React from 'react'
import ClaimSetupForm from '../../common/components/claim-setup-form/ClaimSetupForm'
import ContentMarketing from '../../common/components/content-marketing/ContentMarketing'
import { generatingTargetedLeads, contentMarketingStrategies, sectionContent } from '../../utils/industries-legal/Data'
import DistributionStrategy from '../../common/components/distribution-strategy/DistributionStrategy'
import WeGuaranteeSatisfaction from '../../common/components/we-guarantee-satisfaction/WeGuaranteeSatisfaction'
import Company from '../../common/components/company/Company'
import ClaimYourFreeSetupStrategy from '../../common/components/claim-your-free-setup-strategy/ClaimYourFreeSetupStrategy'
import ContentSection from '../../common/components/content-section/ContentSection'
import SEO from '../../common/components/ui/seo/SEO'

export default function IndustriesLegalPage() {
  return (
    <>
      <SEO
        title='24/7 Legal Call Center for Attorneys and Law Firms'
        description='Our legal intake services are ideal for firms from the small law office with limited staff to the large corporation receiving hundreds of calls, chats and website form submissions related to their injury and lawsuit cases.'
        name='24/7 Legal Call Center for Attorneys and Law Firms'
        type='24/7 Legal Call Center' />
      <div className="container">
        <div className="row py-2 py-lg-5 my-xl-5">
          <div className="col-12 col-lg-6">
            <ContentMarketing contentMarketing={generatingTargetedLeads}/>
          </div>
          <div className="col-12 col-lg-6">
            <div className="claim-setup-form-section-leads position-relative">
              <ClaimSetupForm />
            </div>
          </div>
        </div>
      </div>
      <div className="we-guarantee-satisfaction position-relative">
        <div className="pt-0 container">
          <DistributionStrategy contentMarketingStrategies={contentMarketingStrategies}/>
        </div>
      </div>
      <div className="text-center py-5 mt-5 primary-bg-light">
        <ContentSection sectionContent={sectionContent} />
      </div>
      <div className="container">
        <WeGuaranteeSatisfaction />
      </div>
      <Company />
      <ClaimYourFreeSetupStrategy />
    </>
  )
}
