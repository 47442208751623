import Navbar from './common/components/ui/navbar/Navbar.jsx'
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import 'swiper/css';
import 'swiper/css/pagination';
import { HelmetProvider } from 'react-helmet-async';
import HomePage from './pages/home/index.jsx';
import OutboundMarketingPage from './pages/outbound-marketing/index.jsx';
import InboundMarketingPage from './pages/inbound-marketing/index.jsx';
import Footer from './common/components/ui/footer/Footer.jsx';
import OutboundContentMarketingDistributionPage from './pages/outbound-content-marketing-distribution/index.jsx';
import OutboundLeadGenerationPage from './pages/outbound-lead-generation/index.jsx';
import OutboundCustomerDevelopmentWinBackPage from './pages/outbound-customer-development-winback/index.jsx';
import OutboundB2BListForBusinessGrowthPage from './pages/outbound-b2b-list-for-business-growth/index.jsx';
import OutboundSurveyAndMarketResearchPage from './pages/outbound-survey-and-market-research/index.jsx';
import OutboundPoliticalCampaignPage from './pages/outbound-political-campaign/index.jsx';
import OutboundPublisherAudienceDevelopmentPage from './pages/outbound-publisher-audience-development/index.jsx';
import InboundCustomerServicesPage from './pages/inbound-customer-services/index.jsx';
import InboundOmniChannelPage from './pages/inbound-omnichannel/index.jsx';
import IndustriesLandingPage from './pages/industries-landing/index.jsx';
import IndustriesAutomotivePage from './pages/industries-automotive/index.jsx';
import IndustriesB2BPage from './pages/industries-b2b/index.jsx';
import IndustriesContractorsPage from './pages/industries-contractors/index.jsx';
import IndustriesEcommercePage from './pages/industries-ecommerce/index.jsx';
import IndustriesEducationPage from './pages/industries-education/index.jsx';
import IndustriesFinancialPage from './pages/industries-financial/index.jsx';
import IndustriesFranchisePage from './pages/industries-franchise/index.jsx';
import IndustriesGovernmentPage from './pages/industries-government/index.jsx';
import IndustriesHealthcarePage from './pages/industries-healthcare/index.jsx';
import IndustriesHospitalityPage from './pages/industries-hospitality/index.jsx';
import IndustriesInsurancePage from './pages/industries-insurance/index.jsx';
import IndustriesLegalPage from './pages/industries-legal/index.jsx';
import IndustriesNonprofitPage from './pages/industries-noneprofit/index.jsx';
import IndustriesPoliticalPage from './pages/industries-political/index.jsx';
import IndustriesRetailPage from './pages/industries-retail/index.jsx';
import IndustriesSmallBusinessPage from './pages/industries-small-business/index.jsx';
import IndustriesPublisherPage from './pages/industries-publisher/index.jsx';
import IndustriesTelecomPage from './pages/industries-telecom/index.jsx';
import IndustriesUtilitiesPage from './pages/industries-utilities/index.jsx';
import PrivacyPolicyPage from './pages/privacy-policy/index.jsx';
import TermsAndConditionsPage from './pages/terms-and-conditions/index.jsx';
import DisclaimerPage from './pages/disclaimer/index.jsx';
import LoginPage from './pages/login/index.jsx';

function App() {
  const helmetContext = {};
  return (
    <Router>
      <HelmetProvider context={helmetContext}>
        <Navbar />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/outbound-marketing" element={<OutboundMarketingPage />} />
          <Route path="/inbound-marketing" element={<InboundMarketingPage />} />
          <Route path="/outbound-content-marketing-distribution" element={<OutboundContentMarketingDistributionPage />} />
          <Route path="/outbound-lead-generation" element={<OutboundLeadGenerationPage />} />
          <Route path="/outbound-customer-development" element={<OutboundCustomerDevelopmentWinBackPage />} />
          <Route path="/outbound-b2b-list-for-business-growth" element={<OutboundB2BListForBusinessGrowthPage />} />
          <Route path="/outbound-survey-and-market-search" element={<OutboundSurveyAndMarketResearchPage />} />
          <Route path="/outbound-political-campaign" element={<OutboundPoliticalCampaignPage />} />
          <Route path="/outbound-publisher-audience-development" element={<OutboundPublisherAudienceDevelopmentPage />} />
          <Route path="/inbound-customer-services" element={<InboundCustomerServicesPage />} />
          <Route path="/inbound-omnichannel" element={<InboundOmniChannelPage />} />
          <Route path="/industries-landing" element={<IndustriesLandingPage />} />
          <Route path="/industries-automotive" element={<IndustriesAutomotivePage />} />
          <Route path="/industries-b2b" element={<IndustriesB2BPage />} />
          <Route path="/industries-contractors" element={<IndustriesContractorsPage />} />
          <Route path="/industries-ecommerce" element={<IndustriesEcommercePage />} />
          <Route path="/industries-education" element={<IndustriesEducationPage />} />
          <Route path="/industries-financial" element={<IndustriesFinancialPage />} />
          <Route path="/industries-franchise" element={<IndustriesFranchisePage />} />
          <Route path="/industries-government" element={<IndustriesGovernmentPage />} />
          <Route path="/industries-healthcare" element={<IndustriesHealthcarePage />} />
          <Route path="/industries-hospitality" element={<IndustriesHospitalityPage />} />
          <Route path="/industries-insurance" element={<IndustriesInsurancePage />} />
          <Route path="/industries-legal" element={<IndustriesLegalPage />} />
          <Route path="/industries-noneprofit" element={<IndustriesNonprofitPage />} />
          <Route path="/industries-political" element={<IndustriesPoliticalPage />} />
          <Route path="/industries-retail" element={<IndustriesRetailPage />} />
          <Route path="/industries-small-business" element={<IndustriesSmallBusinessPage />} />
          <Route path="/industries-publisher" element={<IndustriesPublisherPage />} />
          <Route path="/industries-telecom" element={<IndustriesTelecomPage />} />
          <Route path="/industries-utilities" element={<IndustriesUtilitiesPage />} />
          <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
          <Route path="/terms-and-conditions" element={<TermsAndConditionsPage />} />
          <Route path="/disclaimer" element={<DisclaimerPage />} />
          <Route path="/login" element={<LoginPage />} />
        </Routes>
        <Footer />
      </HelmetProvider>
    </Router>
  );
}

export default App;
